import i18n from '@/i18n';

import { cleanConfigFormData } from '@/utils/format';

function InitForm() {
    return {
        return_date_y: '',
        return_date_m: '',
        return_date_d: '',
        return_date: '',
        reconfirmation_schedule: '',
        event_prize: null,
        end_flag: true,
        number: 1,
        province: '',
        shipping_company: '',
        contact_information_number: '',
        reason: '',
        return_status: null,
        instruction: '',
        compatiable_with_jp_logistic_development: '',
        storage_code: '',
        note: '',
        campaign: null,
        shipping_return_fee: '',
        tracking_number: '',

        configFieldShippingReturnFee: {
            isNumber: false,
            isRequired: false,
            isMaxLength: false,
            errorCustom: '',
            valueSync: '',
            typesValidate: ['max255'],
            errorField: i18n.t('pageReturnPrize.shippingReturnFee'),
            error: false
        },
        configFieldReturnDate: {
            error: false,
            errorText: '',
            errorField: i18n.t('pageReturnPrize.returnDate'),
            typesValidate: ['requiredDate', 'date']
        },
        configFieldReconfirmation: {
            error: false,
            errorText: '',
            valueSync: '',
            errorField: i18n.t('pageReturnPrize.reconfirmationSchedule'),
            typesValidate: ['date']
        },
        configFieldProvince: {
            isNumber: false,
            isRequired: false,
            isMaxLength: false,
            errorCustom: '',
            valueSync: '',
            typesValidate: ['max255'],
            errorField: i18n.t('pageReturnPrize.province'),
            error: false
        },
        configFieldShipping: {
            isNumber: false,
            isRequired: false,
            isMaxLength: false,
            errorCustom: '',
            valueSync: '',
            typesValidate: ['max255'],
            errorField: i18n.t('pageReturnPrize.shippingCompany'),
            error: false
        },
        configFieldContactInfo: {
            isNumber: false,
            isRequired: false,
            isMaxLength: false,
            errorCustom: '',
            valueSync: '',
            typesValidate: ['max255'],
            errorField: i18n.t('pageReturnPrize.contactInformationNumber'),
            error: false
        },
        configFieldReason: {
            isNumber: false,
            isRequired: false,
            isMaxLength: false,
            errorCustom: '',
            valueSync: '',
            typesValidate: ['required', 'max255'],
            errorField: i18n.t('pageReturnPrize.reason'),
            error: false
        },
        configFieldInstruction: {
            isNumber: false,
            isRequired: false,
            isMaxLength: false,
            errorCustom: '',
            valueSync: '',
            typesValidate: ['max255'],
            errorField: i18n.t('pageReturnPrize.instruction'),
            error: false
        },
        configFieldLogictis: {
            isNumber: false,
            isRequired: false,
            isMaxLength: false,
            errorCustom: '',
            valueSync: '',
            typesValidate: ['max255'],
            errorField: i18n.t('pageReturnPrize.compatiableWithJp'),
            error: false
        },
        configFieldStorageCode: {
            isNumber: false,
            isRequired: false,
            isMaxLength: false,
            errorCustom: '',
            valueSync: '',
            typesValidate: ['max255'],
            errorField: i18n.t('pageReturnPrize.storageCode'),
            error: false
        },
        configFieldNote: {
            isNumber: false,
            isRequired: false,
            isMaxLength: false,
            errorCustom: '',
            valueSync: '',
            typesValidate: ['max255'],
            errorField: i18n.t('pageReturnPrize.note'),
            error: false
        },
        configFieldSelectCampaign: {
            trackBy: 'id',
            label: 'name',
            errorField: i18n.t('pageReturnPrize.nameCampaign'),
            valueSync: null,
            loading: false,
            error: false,
            isRemove: false,
            isRequired: false,
            typesValidate: ['requiredSelect']
        },
        configFieldSelectPrize: {
            trackBy: 'id',
            label: 'title',
            errorField: i18n.t('pageReturnPrize.namePrize'),
            valueSync: null,
            loading: false,
            error: false,
            isRemove: false,
            isRequired: false,
            typesValidate: ['requiredSelect']
        },
        configFieldSelectReturnStatus: {
            trackBy: 'id',
            label: 'value',
            errorField: i18n.t('pageReturnPrize.returnStatus'),
            valueSync: null,
            loading: false,
            error: false,
            isRemove: false,
            isRequired: false,
            typesValidate: ['requiredSelect']
        },
        configFieldTrackingNumber: {
            isNumber: false,
            isRequired: false,
            isMaxLength: false,
            errorCustom: '',
            valueSync: '',
            typesValidate: ['max255'],
            errorField: i18n.t('pageReturnPrize.trackingNumber'),
            error: false
        }
    };
}

function handleMapData(dataForm) {
    let dataAfterClean = cleanConfigFormData(dataForm);
    return {
        ...dataAfterClean,
        event_prize: {
            id: dataForm.event_prize.id
        },
        return_status: {
            id: dataForm.return_status.id
        },
        campaign: {
            id: dataForm.campaign.id
        }
    };
}

function handleDetailReturnPrize(data) {
    data.data.forEach((item) => {
        if (!item.return_status) item.return_status = { id: '' };
        if (!item.prize)
            item.prize = {
                delivery_company: ''
            };
        // config
        item.configReturnStatus = {
            trackBy: 'id',
            label: 'value',
            errorField: i18n.t('pageReturnPrize.returnStatus'),
            valueSync: null,
            loading: false,
            error: false,
            isRemove: false,
            isRequired: false,
            typesValidate: ['requiredSelect']
        };
        item.configFieldReturnDate = {
            errorText: '',
            valueSync: '',
            classCustom: 'form-control-sm',
            error: !item.return_date,
            errorField: i18n.t('pageReturnPrize.returnDate'),
            typesValidate: []
        };
        item.configFieldReconfirmation = {
            errorText: '',
            valueSync: '',
            classCustom: 'form-control-sm',
            error: false,
            errorField: i18n.t('pageReturnPrize.reconfirmationSchedule'),
            typesValidate: []
        };
    });
    return data;
}

export { InitForm, handleMapData, handleDetailReturnPrize };
